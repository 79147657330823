<script setup lang="ts">
withDefaults(defineProps<{
  show: boolean
  title?: string
  text?: string
  width?: string
  confirmText?: string
  confirmDisabled?: boolean
}>(), { width: '700px' })
const emit = defineEmits<{ (e: 'close'): void, (e: 'confirm'): void }>()
</script>

<template>
  <n-modal
    :mask-closable="false"
    :show="show"
    :style="`width: ${width}`"
    @esc="emit('close')"
    @close="emit('close')"
  >
    <div>
      <header class="bg-primary-400 text-white rounded-t px-4 py-3">
        <h3> {{ title ?? $t('confirmation.popup.title') }} </h3>
      </header>

      <div class="bg-white rounded-b">
        <div class="px-4 py-3">
          <p v-if="text">
            {{ text }}
          </p>

          <slot />

          <n-divider class="!mb-3 !mt-4" />
          <div class="flex justify-end items-center gap-2">
            <n-button @click="$emit('close')">
              {{ $t('button.cancel') }}
            </n-button>
            <n-button type="primary" :disabled="confirmDisabled" @click="$emit('confirm')">
              {{ confirmText ?? $t('button.confirm') }}
            </n-button>
          </div>
        </div>
      </div>
    </div>
  </n-modal>
</template>
